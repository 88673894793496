import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import './ReviewEdit.scss';
import { getSourceLogoUrl } from 'utils/util-element';
import {
  MozartWidgetLayOut,
  MozartStarRating,
  MozartTextBox,
  MozartButton,
  MozartSelectField,
  MozartModal,
  MozartLoading,
  MozartCheckBox
} from '@pgforsta/mozart_ui';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { sendToSupport, forwardAndNext, loadClientConfiguration, loadAiResponse } from 'redux/actions/responderAction';
import { approveAndNext } from 'redux/actions/approvalAction';
import moment from 'moment-timezone';
import { Share } from 'components';
import Clutch from '@pgforsta/clutch-ui';

class ReviewEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      seletedTemplate: '',
      modal: false,
      supportChangeMessage: '',
      templateResponse: '',
      taskDetails: [],
      failedStatus: false,
      failedStatusType: '',
      buttonActive: true,
      templateResponseStatus: '',
      customLoader: false,
      shareReviewFlag: false,
      shareReviewEmail: '',
      shareReviewSubject: '',
      shareReviewMessage: '',
      customShareReviewFlag: false,
      starActivated: true,
      aiGeneratedResponse: '',
      useAiResponseButton: false,
      enableAiResponse: false,
      selectedAiResponse: '',
      aiResponseErrorLabel: '',
      randomKey: Math.random(),
      starValueAi: null
    };
    this.buttonActive = true;
  }
  componentDidMount() {
    this.getClientConfiguration(this.props.statusData.clientId, this.props.statusData.contentId, this.props.viewContainer);
  }

  componentWillReceiveProps(nextProps) {
    const { tempateArrayD, statusData, viewContainer } = nextProps;
    if (
      this.props.tempateArrayD.data !== tempateArrayD.data ||
      this.props.statusData !== statusData ||
      this.props.viewContainer !== viewContainer
    ) {
    const emailArray = [];
    const taskResponse = statusData.response !== null ? statusData.response : "";
    const templateArray =
      tempateArrayD && tempateArrayD.data && tempateArrayD.data.length > 0
        ? tempateArrayD.data.filter(
            (asset) => asset.id === nextProps.statusData.templateIdUsed
          )
        : [];
    const tempSelectedArray =
      statusData.templateIdUsed > 0 ? templateArray : [];
    const templateSelecteVal =
      tempSelectedArray.length > 0 ? tempSelectedArray[0].message : "";
    const tempValue =
      tempSelectedArray.length > 0 ? templateSelecteVal : taskResponse;
    const shareReview = statusData.shareReview ? statusData.shareReview : false;
    if (statusData.toEmails && statusData.toEmails.length > 0) {
      statusData.toEmails.forEach((item) => {
        emailArray.push(item.trim());
      });
    }
    const shareSubject = statusData.subject ? statusData.subject : "";
    const shareMessage = statusData.shareMessage ? statusData.shareMessage : "";
    let customShareReview = false;
    if (shareReview && emailArray.length > 0 && shareSubject && shareMessage) {
      customShareReview = true;
    }
    this.setState({
      seletedTemplate: statusData.templateIdUsed,
      templateResponse: tempValue,
      taskDetails: statusData,
      shareReviewFlag: shareReview,
      shareReviewEmail: emailArray,
      shareReviewSubject: shareSubject,
      shareReviewMessage: shareMessage,
      customShareReviewFlag: customShareReview,
    });
    if(viewContainer === 'APPROVER')
    {
      const shareAiMessage = statusData.aiResponse ? statusData.aiResponse : "";
      const shareStarValueAi = statusData.aiRating ? statusData.aiRating : 0 ;
      const shareStarActivated = statusData.aiRating > 0 ? false : true;
      const shareErrorLabel = shareAiMessage === "" ? "No AI response available" : "";
      this.setState({aiGeneratedResponse: shareAiMessage, starValueAi: shareStarValueAi, aiResponseErrorLabel: shareErrorLabel, 
      starActivated: shareStarActivated
      });
    }
  }}

  getClientConfiguration(clientId, contentId, viewContainer) {
    let enableAutoReply = false;
    this.props.loadClientConfiguration(clientId).then((response) => {
      if (response?.payload?.data) {
        if (response?.payload?.data?.products['bha']?.answerAssistManageEngagement){
          enableAutoReply = true;
        }
      }
      if (enableAutoReply) {
        this.setState({ enableAiResponse: true});
        if (viewContainer === 'RESPONDER') {
          if (contentId != null) {
            this.getAiResponse(clientId, contentId);
          } else {
            this.setState({ aiResponseErrorLabel: "No AI response available" });
          }
      }
      } else {
        this.setState({ enableAiResponse: false});
      }
    });
  }
  getAiResponse(clientId, contentId) {
    this.setState({
      customLoader: true,
    });
    this.props.loadAiResponse(clientId, contentId).then((element) => {
      if (element && element.payload.status && element.payload.status === 200) {
        this.setState({
          aiGeneratedResponse: element.payload.data.response,
          aiResponseErrorLabel: '',
          customLoader: false
        });
      } else {
        this.setState({
          aiResponseErrorLabel:
            "Unable to generate AI response. Please use the refresh button to reload",
            customLoader: false,
          aiGeneratedResponse: ''
        });
      }
    });
  }

  getLimitCharacters() {
    const { sourceId, twitterHandle, authorName } = this.state.taskDetails;
    if (sourceId === null) {
      return 3000;
    }
    if (sourceId === 100125 && twitterHandle) {
      return 279 - twitterHandle.length;
    }
    if (sourceId === 100283) {
      return 10000;
    }
    if (sourceId === 101180 || sourceId === 101350 || sourceId === 101351) {
      return 2198 - authorName.length;
    }
    if (sourceId === 100365) {
      return 500;
    }
    return 3000;
  }

  getMinCharacters() {
    const { sourceId } = this.state.taskDetails;
    if (sourceId === null) {
      return 0;
    }
    if (sourceId === 100283 || sourceId === 100176) {
      return 49;
    }
    return 0;
  }

  getShowRemainingCharacter() {
    const { sourceId } = this.props.statusData;
    if (sourceId === null) {
      return 0;
    }
    if (sourceId === 100365 || sourceId === 100125) {
      return true;
    }
    return false;
  }

  getFailedStatus() {
    return (
      <MozartModal
        title="Engagement Status"
        key="engagement_status"
        show={this.state.failedStatus}
        onClose={() => this.closeFailedStatusModal()}
        disableSubmit
      >
        Engagement Status is Failed!.
      </MozartModal>
    );
  }

  setTemplateId(seletedTemplate) {
    const { tempateArrayD } = this.props;
    let selectedTemplateId = seletedTemplate;
    const { templateResponse } = this.state;
    const templateArray =
      tempateArrayD && tempateArrayD.data && tempateArrayD.data.length > 0
        ? tempateArrayD.data.filter((asset) => asset.id === selectedTemplateId)
        : [];
    const templateSelecteValue =
      templateArray.length > 0 ? templateArray[0].message : "";
    if (templateSelecteValue !== templateResponse) {
      selectedTemplateId = "";
    }
    return selectedTemplateId;
  }

  sendToSupportModal() {
    this.setState({ modal: true });
  }

  supportDataChange(e) {
    this.setState({ supportChangeMessage: e });
  }

  sendSupportData() {
    const { username } = this.props.userInfo;
    const { taskId, clientId } = this.props.statusData;
    const { viewContainer } = this.props;
    const { supportChangeMessage, seletedTemplate } = this.state;
    const messageBody = {
      message: supportChangeMessage,
      taskId: taskId,
      templateId: this.setTemplateId(seletedTemplate),
      userId: username,
      shareReview: this.state.shareReviewFlag
        ? this.state.shareReviewFlag
        : false,
      toEmails:
        this.state.shareReviewEmail && this.state.shareReviewEmail.length > 0
          ? this.state.shareReviewEmail
          : null,
      subject: this.state.shareReviewSubject
        ? this.state.shareReviewSubject
        : null,
      shareMessage: this.state.shareReviewMessage
        ? this.state.shareReviewMessage
        : null,
      aiMessage:
        this.state.aiGeneratedResponse && this.state.aiResponseErrorLabel === ""
          ? this.state.aiGeneratedResponse
          : null,
      starRating: this.state.starValueAi,    
      ipAddress: null,
      clientId: clientId,
    };
    this.setState({ customLoader: true }, () => {
      this.props.sendToSupport(taskId, messageBody).then(() => {
        this.props.supportData(viewContainer);
        this.setState({ modal: false, customLoader: false });
      });
    });
  }

  forwardAndNext() {
    const { username } = this.props.userInfo;
    const { taskId, clientId } = this.props.statusData;
    const { viewContainer } = this.props;
    const { seletedTemplate, templateResponse } = this.state;
    const messageBody = {
      message: templateResponse,
      taskId: taskId,
      templateId: this.setTemplateId(seletedTemplate),
      userId: username,
      shareReview: this.state.shareReviewFlag
        ? this.state.shareReviewFlag
        : false,
      toEmails:
        this.state.shareReviewEmail && this.state.shareReviewEmail.length > 0
          ? this.state.shareReviewEmail
          : null,
      subject: this.state.shareReviewSubject
        ? this.state.shareReviewSubject
        : null,
      shareMessage: this.state.shareReviewMessage
        ? this.state.shareReviewMessage
        : null,
      aiMessage:
        this.state.aiGeneratedResponse && this.state.aiResponseErrorLabel === ""
          ? this.state.aiGeneratedResponse
          : null,
      starRating: this.state.starValueAi,
      ipAddress: null,
      clientId: clientId,
    };
    this.setState({ customLoader: true }, () => {
      this.props.forwardAndNext(taskId, messageBody).then((response) => {
        if ( response &&
          response.payload.data &&
          response.payload.data.taskStatus === "FAILED" &&
          !this.state.taskDetails.approvalRequired
        ) {
          this.setState(
            {
              failedStatus: true,
              failedStatusType: "forward",
              customLoader: false,
            },
            () => {
              this.getFailedStatus();
            }
          );
        } else {
        this.props.forwardData(viewContainer);
        this.setState({
          customLoader: false,
        });
        }
      });
    });
  }

  approveAndNext() {
    const { username } = this.props.userInfo;
    const { taskId, clientId } = this.props.statusData;
    const { viewContainer } = this.props;
    const { seletedTemplate, templateResponse } = this.state;
    const messageBody = {
      message: templateResponse,
      taskId: taskId,
      templateId: this.setTemplateId(seletedTemplate),
      userId: username,
      shareReview: this.state.shareReviewFlag
        ? this.state.shareReviewFlag
        : false,
      toEmails:
        this.state.shareReviewEmail && this.state.shareReviewEmail.length > 0
          ? this.state.shareReviewEmail
          : null,
      subject: this.state.shareReviewSubject
        ? this.state.shareReviewSubject
        : null,
      shareMessage: this.state.shareReviewMessage
        ? this.state.shareReviewMessage
        : null,
      aiMessage:
        this.state.aiGeneratedResponse && this.state.aiResponseErrorLabel === ""
          ? this.state.aiGeneratedResponse
          : null,
      starRating: this.state.starValueAi,
      clientId: clientId,  
    };
    this.setState({ customLoader: true }, () => {
      this.props.approveAndNext(taskId, messageBody).then((response) => {
        if (
          !this.state.taskDetails.approvalRequired &&
          response.payload.data &&
          response.payload.data.taskStatus === "FAILED"
        ) {
          this.setState(
            {
              failedStatus: true,
              failedStatusType: "approver",
              customLoader: false,
            },
            () => {
              this.getFailedStatus();
            }
          );
        } else {
          this.props.approverData(viewContainer);
        }
      });
    });
  }

  responseMessage(e) {
    const responseFromTemp = e;
    this.state.buttonActive = this.buttonActive;
    this.setState({
      templateResponse: responseFromTemp,
    });
  }

  templateSelectedBox(e) {
    const { tempateArrayD } = this.props;
    const templateSelected = tempateArrayD.data.filter((person) => person.id === e);
    let templateResponseStatus = "";
    if (this.state.seletedTemplate === e) {
      templateResponseStatus = "active";
    }
    this.setState({
      seletedTemplate: e,
      templateResponse: templateSelected[0].message,
      templateResponseStatus: templateResponseStatus,
    });
  }

  closeModal() {
    this.setState({ modal: false });
  }

  closeFailedStatusModal() {
    this.setState({ failedStatus: false });
    if (this.state.failedStatusType === "approver") {
      this.props.approverData(this.props.viewContainer);
    } else if (this.state.failedStatusType === "forward") {
      this.props.forwardData(this.props.viewContainer);
      this.setState({
        customLoader: false,
      });
    }
  }

  checkShareDetail() {
    let statusVal = false;
    if (
      this.state.shareReviewEmail &&
      this.state.shareReviewEmail.length > 0 &&
      this.state.shareReviewSubject &&
      this.state.shareReviewSubject.length > 0 &&
      this.state.shareReviewMessage &&
      this.state.shareReviewMessage.length > 0
    ) {
      statusVal = true;
    }
    return statusVal;
  }

  validate() {
    const original = this.state.templateResponse;
    const removeNames = original.match(/[^[\]]+(?=])/g);
    let afterRemove = original;
    if (removeNames !== null) {
      removeNames.forEach((removeName) => {
        afterRemove = original.replace(removeName, "");
      });
    }
    this.buttonActive = afterRemove.trim().length < 4;
    const limitCheck = afterRemove.trim().length > this.getLimitCharacters();
    if (this.state.shareReviewFlag && !this.checkShareDetail()) {
      return true;
    }
    return afterRemove.trim().length < 4 || limitCheck;
  }

  validateSend() {
    const original = this.state.supportChangeMessage;
    const removeNames = this.state.supportChangeMessage.match(/[^[\]]+(?=])/g);
    let afterRemove = original;
    if (removeNames !== null) {
      removeNames.forEach((removeName) => {
        afterRemove = original.replace(removeName, "");
      });
    }
    if (this.state.shareReviewFlag && !this.checkShareDetail()) {
      return true;
    }
    return afterRemove.trim().length < 3;
  }

  handleShareReview(data) {
    let shareReviewVal = false;
    if (data && data.length > 0) {
      shareReviewVal = true;
    }
    this.setState({
      shareReviewFlag: shareReviewVal,
    });
  }

  handleShareFormChanges(value, type) {
    if (type === "email") {
      this.setState({
        shareReviewEmail: value,
      });
    }
    if (type === "subject") {
      this.setState({
        shareReviewSubject: value,
      });
    }
    if (type === "message") {
      this.setState({
        shareReviewMessage: value,
      });
    }
  }

  starRating(e) {
    if (e !== null) {
      this.setState({
        starValue: e,
      });
    }
  }
  const;
  render() {
    const regexReplace = /[<|>|\?|`|~|%|{|}|\||\+|\[|\]|\$|\=|\*|\\|\^]/g;
    const { statusData, tempateArrayD, loadingAiResponse } = this.props;
    const { clientId, contentId } = this.props.statusData;
    const nexState =
      tempateArrayD && tempateArrayD.data &&
      tempateArrayD.data.map((items) => {
        if (items) {
          return {
            ...items,
            id: items.id,
            name: items.name,
            response: items.message,
            value: items.id,
          };
        }
        return items;
      });
    return (
      <Container fluid>
        <Row className="template_filter">
          {this.state.customLoader && loadingAiResponse && <MozartLoading />}
          <Col xs={6} md={6}>
            &nbsp;
          </Col>
            <Col xs={6} md={6}>
              {nexState && nexState.length > 0 && (
                <Row className="template-filter-box">
                  <Col xs={4} md={4} className="template_filter_lable">
                    Template
                  </Col>
                  <Col xs={8} md={8} className="select-box-res">
                    <MozartSelectField
                      data={nexState}
                      value={
                        this.state.seletedTemplate !== 0
                          ? this.state.seletedTemplate
                          : ""
                      }
                      defaultValue={
                        this.state.seletedTemplate !== 0
                          ? this.state.seletedTemplate
                          : ""
                      }
                      onChange={(e) => this.templateSelectedBox(e)}
                      width="410px"
                      hintText="Select a Template"
                      key={
                        this.state.seletedTemplate !== 0
                          ? this.state.seletedTemplate
                          : ""
                      }
                    />
                  </Col>
                </Row>
              )}
            </Col>
        </Row>
        <Row className="review_edit">
          <Col xs={6} md={6} className="lableHeader-table">
            <MozartWidgetLayOut
              navigation={
                <div style={{ cursor: "pointer", color: "#F5F4FF" }}>
                  Navigation
                </div>
              }
              header={
                <Row
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    backgroundColor: "#F5F4FF",
                    borderTop: "1px solid #715AFF",
                    borderBottom: "none !important",
                    margin: "-10px",
                    bordertop: "1px solid #715AFF",
                    height: "152px",
                    borderTopLeftRadius: "5px",
                    borderTopRightRadius: "5px",
                    borderLeft: "1px solid #715AFF",
                    borderRight: "1px solid #715AFF",
                  }}
                >
                  <Col xs={6} md={6} className="review-lable">
                    <Row>
                      <Col className="review-lable-main">
                        <Row>
                          <Col className="lable-header">REVIEW By</Col>
                        </Row>
                        <Row>
                          <Col className="lable-header-value">
                            {statusData.author}
                          </Col>
                        </Row>
                        <Row>
                          <Col className="lable-header-least">
                            {statusData.mentionTime !== "null"
                              ? moment(statusData.mentionTime)
                                  .tz("America/Chicago")
                                  .format("DD-MMM-YYYY HH:MM:SS")
                              : ""}
                          </Col>
                        </Row>
                      </Col>
                      {statusData.hasRating ? (
                        <Col className="review-lable-rating">
                          <Row>
                            <Col className="lable-header">RATING</Col>
                          </Row>
                          <Row style={{ marginLeft: "22px" }}>
                            <Col xs={6} md={6}>
                              <MozartStarRating
                                style={{ fontSize: "15px" }}
                                key={statusData.taskId}
                                stars={statusData.rating}
                              />
                            </Col>
                            <Col xs={6} md={6} className="lable-header-value">
                              {statusData.rating}
                            </Col>
                          </Row>
                        </Col>
                      ) : (
                        ""
                      )}
                    </Row>
                  </Col>
                  <Col xs={6} md={6} className="review-lable-social">
                    <Row>
                      <Col className="review-lable-social-main">
                        <Row>
                          <Col className="lable-header">SOURCE</Col>
                        </Row>
                        <Row>
                          <Col className="lable-header-value">
                            <div
                              className="lable-header-icon"
                              style={{ display: "inline", marginTop: "5px" }}
                            >
                              {getSourceLogoUrl(
                                statusData.documentLink,
                                statusData.sourceName,
                                statusData.sourceLogoUrl
                              )}
                              <span
                                className="lable-header-social"
                                style={{ marginLeft: "5px" }}
                              >
                                <a
                                  target="_blank"
                                  style={{ color: "#403788" }}
                                  rel="noopener noreferrer"
                                  href={statusData.documentLink}
                                >
                                  {statusData.sourceName}
                                </a>
                              </span>
                            </div>
                          </Col>
                        </Row>
                      </Col>
                      <Col className="review-lable-social-location">
                        <Row>
                          <Col className="lable-header">LOCATION/PROVIDER</Col>
                        </Row>
                        <Row>
                          <Col
                            className="lable-header-value"
                            style={{ overflowWrap: "break-word" }}
                          >
                            {statusData.providerLocationName}
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              }
              contentStyle={{
                height: this.state.enableAiResponse ? "383px" : "310px",
                overflow: "scroll",
              }}
            >
              <div
                className="review-box-content"
                dangerouslySetInnerHTML={{
                  __html: statusData.review !== null ? statusData.review : "",
                }}
              />
            </MozartWidgetLayOut>
          </Col>
          <Col xs={6} md={6} className="lableHeader-table-response">
            <MozartWidgetLayOut
              navigation={
                <div style={{ cursor: "pointer", color: "#F5F4FF" }}>
                  Navigation
                </div>
              }
              header={
                <Row
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    backgroundColor: "#F5F4FF",
                    borderTop: "1px solid #715AFF",
                    margin: "-10px",
                    bordertop: "1px solid #715AFF",
                    height: "40px",
                    borderTopLeftRadius: "5px",
                    borderTopRightRadius: "5px",
                    borderLeft: "1px solid #715AFF",
                    borderRight: "1px solid #715AFF",
                    paddingTop: "8px",
                  }}
                >
                  <Col className="lable-header-response-value">
                    Edit Response
                  </Col>
                </Row>
              }
              contentStyle={{
                height: this.state.enableAiResponse ? "497px" : "422px",
              }}
            >
              <div className="review-box-content">
                <MozartTextBox
                  key={
                    React.createRef() +
                    this.state.seletedTemplate +
                    this.state.templateResponseStatus +
                    this.state.aiGeneratedResponse +
                    this.state.starActivated +
                    this.state.randomKey + 
                    this.props.statusData.taskId
                  }
                  id="response_textarea"
                  className="response_textarea"
                  onChange={(e) => this.responseMessage(e)}
                  defaultValue={this.state.templateResponse}
                  limit={this.getLimitCharacters()}
                  minChars={this.getMinCharacters()}
                  showRemainingCharacter={(e) =>
                    this.getShowRemainingCharacter(e)
                  }
                  value={this.state.templateResponse}
                  name="response"
                  regexReplace={
                    statusData.sourceId === 100365 ? regexReplace : ""
                  }
                  allowNewLine={
                    statusData.sourceId === 100365 ? "true" : "false"
                  }
                  multiLine
                />
                {this.state.enableAiResponse && (
                  <div className="response_generation">
                    <div className="aiResponseHeader">
                      <h6 className="ai_response">AI Generated Response</h6>
                      {this.state.aiResponseErrorLabel != '' && <p className="ai_errorLabel">{this.state.aiResponseErrorLabel}</p>}
                      <Clutch.Button
                        endIcon={
                          <i className="icon-Reset" aria-hidden="true" />
                        }
                        onClick={() => this.getAiResponse(clientId, contentId)
                        }
                      ></Clutch.Button>
                    </div>
                    <MozartTextBox
                      label="AI Generated Response"
                      className="ai_generated"
                      key={this.state.aiGeneratedResponse}
                      id="ai_generated"
                      multiLine
                      value={this.state.aiGeneratedResponse}
                      disabled
                    />
                    <div className="star_rating">
                      <h6 className="rating_text">
                        How accurate was this response?
                      </h6>
                      <div className="rating">
                        <Clutch.StarRating
                          precision={0.5}
                          testid="starRating"
                          hideRatingValue
                          value={this.state.starValueAi}
                          readOnly={!this.state.aiGeneratedResponse || !this.state.aiGeneratedResponse.length ? true : false}
                          onStarClick={(e) => {
                            if (e > 0) this.setState({ starActivated: false , starValueAi: e});
                            else {
                              this.setState({
                                starActivated: true,
                                useAiResponseButton: false,
                                templateResponse: "",
                                starValueAi: null
                              });
                            }
                          }}
                        />
                        <span className="ratingValidation">
                          {this.state.starActivated && this.state.aiGeneratedResponse !='' && "select a rating"}
                        </span>
                      </div>
                      <MozartButton
                        background
                        data="Use AI Response"
                        disabled={this.state.starActivated || !this.state.aiGeneratedResponse || !this.state.aiGeneratedResponse.length}
                        id="useAiButton"
                        onClick={() => {
                          this.setState({
                            useAiResponseButton: true,
                            templateResponse: this.state.aiGeneratedResponse,
                            selectedAiResponse: this.state.aiGeneratedResponse,
                            randomKey: Math.random()
                          });
                        }}
                      />
                    </div>
                  </div>
                )}
              </div>
            </MozartWidgetLayOut>
            <div className="response-count">{`REVIEW RESPONDED TODAY:  ${statusData.reviewsRespondedToday}`}</div>
          </Col>
        </Row>
        <Row className="edit-submit">
          <Col xs={6} md={6}>
            &nbsp;
          </Col>
          <Col xs={6} md={6} style={{ display: "flex" }}>
            <Col xs={6} md={6} className="share-review-block">
              <MozartCheckBox
                colorStyle="purple"
                onChange={(data) => {
                  this.handleShareReview(data);
                }}
                id="deleteCheckbox"
                content={[
                  {
                    name: "Share Review",
                    value: 1,
                    checked: this.state.shareReviewFlag
                      ? this.state.shareReviewFlag
                      : false,
                  },
                ]}
                name="shareReview"
                key={Math.random()}
              >
                Share Review
              </MozartCheckBox>
              {this.state.shareReviewFlag && [
                <Share
                  key="shareblock"
                  shareReviewEmail={this.state.shareReviewEmail}
                  shareReviewSubject={this.state.shareReviewSubject}
                  shareReviewFlag={this.state.shareReviewFlag}
                  shareReviewMessage={this.state.shareReviewMessage}
                  handleShareFormChanges={(data, type) => {
                    this.handleShareFormChanges(type, data);
                  }}
                />,
              ]}
            </Col>
            <Col xs={6} md={6}>
              <Row className="button-container">
                <Col xs={6} md={6}>
                  <div className="send-button-container">
                    <MozartButton
                      data="Send to Support"
                      onClick={(e) => this.sendToSupportModal(e)}
                      style={{
                        color: "#606161",
                        height: "40px",
                        width: "auto",
                        paddingTop: "5px",
                      }}
                    />
                  </div>
                </Col>
                <Col xs={6} md={6}>
                  {this.props.viewContainer === "APPROVER" ? (
                    <div>
                      <MozartButton
                        background
                        data="Approve and Next"
                        disabled={this.validate()}
                        onClick={(e) => this.approveAndNext(e)}
                        style={{
                          height: "40px",
                          width: "150px",
                          paddingTop: "5px",
                        }}
                      />
                    </div>
                  ) : (
                    <div>
                      <MozartButton
                        background
                        data={`${
                          statusData.approvalRequired
                            ? "Forward and Next"
                            : "Respond and Next"
                        }`}
                        disabled={this.validate()}
                        key={this.buttonActive}
                        onClick={(e) => this.forwardAndNext(e)}
                        style={{
                          height: "40px",
                          width: "150px",
                          paddingTop: "5px",
                        }}
                      />
                    </div>
                  )}
                </Col>
              </Row>
            </Col>
          </Col>
        </Row>
        <MozartModal
          title="Support Request"
          key="send_support"
          show={this.state.modal}
          onClose={() => this.closeModal()}
          disableSubmit
        >
          <MozartTextBox
            customRef={React.createRef()}
            key="send_textarea"
            id="send_textarea"
            className="response_textarea"
            onChange={(e) => this.supportDataChange(e)}
            name="comment"
            multiLine
          />
          <MozartButton
            background
            data="Send and Next"
            disabled={this.validateSend()}
            onClick={(e) => this.sendSupportData(e)}
            style={{
              height: "40px",
              width: "150px",
              paddingTop: "5px",
              marginRight: "0px",
              marginTop: "22px",
            }}
          />
        </MozartModal>
        {this.getFailedStatus()}
      </Container>
    );
  }
}

ReviewEdit.propTypes = {
  sendToSupport: PropTypes.func,
  forwardAndNext: PropTypes.func,
  taskId: PropTypes.string,
  statusData: PropTypes.object,
  tempateArrayD: PropTypes.object,
  userInfo: PropTypes.object,
  viewContainer: PropTypes.string,
  approveAndNext: PropTypes.func,
  forwardData: PropTypes.func,
  supportData: PropTypes.func,
  approverData: PropTypes.func,
  rating: PropTypes.number,
  loadClientConfiguration: PropTypes.func,
  loadAiResponse: PropTypes.func,
  loadingAiResponse: PropTypes.bool
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      sendToSupport,
      forwardAndNext,
      approveAndNext,
      loadClientConfiguration,
      loadAiResponse,
    },
    dispatch
  );

function mapStateToProps(state) {
  return {
    loadingResponder: state.responderReducer.loadingResponder,
    approveAndNext: state.approvalReducer,
    getToSupport: state.responderReducer,
    loadingAiResponse: state.responderReducer.loadingAiResponse
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ReviewEdit);
