import {
  LOAD_RESPONDER,
  LOAD_RESPONDER_SUCCESS,
  LOAD_RESPONDER_FAIL,
  LOAD_SEND,
  LOAD_SEND_SUCCESS,
  LOAD_SEND_FAIL,
  LOAD_FORWARD,
  LOAD_FORWARD_SUCCESS,
  LOAD_FORWARD_FAIL,
  LOAD_ENABLED,
  LOAD_ENABLED_SUCCESS,
  LOAD_ENABLED_FAIL,
  LOAD_RESPONSE,
  LOAD_RESPONSE_SUCCESS,
  LOAD_RESPONSE_FAIL,
} from "redux/actions/responderAction";

const initialState = {
  loadedResponder: false,
  loadingResponder: false,
  loadedClientConfiguration: false,
  loadingClientConfiguration: false,
  loadedAiResponse: false,
  loadingAiResponse: false,
  loading: false,
  responder: [],
  getToSupport: [],
  getToForward: [],
  template: [],
  enabled: [],
  aiResponse: {},
};

export default function responderReducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD_RESPONDER:
      return {
        ...state,
        loadingResponder: true,
        loadedResponder: false,
        loading: true,
      };
    case LOAD_RESPONDER_SUCCESS:
      const currResponder = state.responder
        ? { ...state.responder, ...action.payload.data }
        : action.payload.data;
      return {
        ...state,
        loadingResponder: false,
        loadedResponder: true,
        loading: false,
        responder: currResponder,
      };
    case LOAD_RESPONDER_FAIL:
      return {
        ...state,
        loadingResponder: false,
        loadedResponder: false,
        loading: false,
        error: action.error,
      };
    case LOAD_RESPONSE:
      return {
        ...state,
        loadingAiResponse: true,
        loadedAiResponse: false,
      };
    case LOAD_RESPONSE_SUCCESS:
      return {
        ...state,
        loadingAiResponse: false,
        loadedAiResponse: true,
        aiResponse: action.payload.data,
      };
    case LOAD_RESPONSE_FAIL:
      return {
        ...state,
        loadingAiResponse: false,
        loadedAiResponse: false,
        error: action.error,
      };
    case LOAD_ENABLED:
      return {
        ...state,
        loadingClientConfiguration: true,
        loadedClientConfiguration: false,
      };
    case LOAD_ENABLED_SUCCESS:
      return {
        ...state,
        loadingClientConfiguration: false,
        loadedClientConfiguration: true,
        enabled: action.payload.data,
      };
    case LOAD_ENABLED_FAIL:
      return {
        ...state,
        loadingClientConfiguration: false,
        loadedClientConfiguration: false,
        error: action.error,
      };
    case LOAD_SEND:
      return {
        ...state,
        loadingResponder: true,
        loadedResponder: false,
      };
    case LOAD_SEND_SUCCESS:
      return {
        ...state,
        loadingResponder: false,
        loadedResponder: true,
        getToSupport: action.payload.data,
      };
    case LOAD_SEND_FAIL:
      return {
        ...state,
        loadingResponder: false,
        loadedResponder: false,
        error: action.error,
      };
    case LOAD_FORWARD:
      return {
        ...state,
        loadingResponder: true,
        loadedResponder: false,
      };
    case LOAD_FORWARD_SUCCESS:
      return {
        ...state,
        loadingResponder: false,
        loadedResponder: true,
        getToForward: action.payload.data,
      };
    case LOAD_FORWARD_FAIL:
      return {
        ...state,
        loadingResponder: false,
        loadedResponder: false,
        error: action.error,
      };
    default:
      return state;
  }
}
