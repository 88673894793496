import React, { Component } from 'react';
import { Container, Col } from 'react-bootstrap';
import { Header, ReviewEdit, ReviewStatus } from 'components';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { loadBreadCrums } from 'redux/actions/breadcrumAction';
import { loadApproval } from 'redux/actions/approvalAction';
import { loadTaskUser } from 'redux/actions/taskUserAction';
import { load as loadAuth } from 'redux/actions/authAction';
import { loadTemplate } from 'redux/actions/templateAction';
import { MozartLoading, MozartWidgetLayOut } from '@pgforsta/mozart_ui';
import './ApproverView.scss';
import { meUserRole } from 'utils/util-element';

class ApproverView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      viewContainer: 'APPROVER',
      data: [],
      load: false
    };
    meUserRole(this.props.permissions, 'APPROVER');
    this.templatePayload = {
      replaceTag: true,
      responseTypes: ['R_O_E'],
    };
  }

  componentDidMount() {
    this.props.loadAuth();
    this.props.loadBreadCrums(this.getBreadCrums());
    const role = 'APPROVER';
    this.props.loadTaskUser(role).then(() => {
      if (this.props.task) {
        const { taskId } = this.props.task;
        this.props.loadApproval(taskId, role).then(() => {
          const { clientId, mentionId } = this.props.approval;
          this.setState({ data: this.props.approval });
          const templatePayload = {
            ...this.templatePayload, 
            mentionId
          };
          this.props.loadTemplate(clientId, templatePayload);
        });
      }
    });
  }

  getBreadCrums() {
    return [
      { id: 1, name: 'Approval', href: 'approval' }
    ];
  }

  approverData(role) {
    this.props.loadTaskUser(role).then(() => {
      if (this.props.task) {
        const { taskId } = this.props.task;
        if (taskId !== 0) {
          this.props.loadApproval(taskId, role).then(() => {
            const { clientId, mentionId } = this.props.approval;
            this.setState({ load: true }, () => {
              setTimeout(() => {
                this.setState({ load: false }, () => {
                  setTimeout(() => {
                    this.setState({ data: this.props.approval });
                  }, 500);
                });
              }, 3000);
            });
            const templatePayload = {
              ...this.templatePayload, 
              mentionId
            };
            this.props.loadTemplate(clientId, templatePayload);
          });
        } else {
          window.location.href = '/#/';
        }
      }
    });
  }

  supportData(role) {
    this.props.loadTaskUser(role).then(() => {
      if (this.props.task) {
        const { taskId } = this.props.task;
        if (taskId !== 0) {
          this.props.loadApproval(taskId, role).then(() => {
            const { clientId, mentionId } = this.props.approval;
            this.setState({ load: true }, () => {
              setTimeout(() => {
                this.setState({ load: false }, () => {
                  setTimeout(() => {
                    this.setState({ data: this.props.approval });
                  }, 500);
                });
              }, 3000);
            });
            const templatePayload = {
              ...this.templatePayload, 
              mentionId
            };
            this.props.loadTemplate(clientId, templatePayload);
          });
        } else {
          window.location.href = '/#/';
        }
      }
    });
  }

  render() {
    const { loadingApproval, loadingTask } = this.props;
    return [<Header />,
      (loadingTask || loadingApproval || this.state.load) ?
        <MozartLoading style={{ marginTop: '30px' }} loading={loadingTask || loadingApproval || this.state.load} />
        :
        (
          <Container fluid className="Approver">
            <Col xs={12} md={12} className="lableHeader">Approval</Col>
            {(Object.keys(this.state.data).length === 0 || this.state.data.taskId === 0) && <MozartWidgetLayOut contentStyle={{ height: '100px' }}><div style={{ padding: '1em' }}>There is no Task available to engage in any queues</div></MozartWidgetLayOut>}
            {(this.state.data !== undefined && this.state.data.taskId > 0) &&
              <ReviewStatus statusData={this.state.data} viewContainer={this.state.viewContainer} />}
            {(this.state.data !== undefined && this.state.data.taskId > 0) &&
              (
                <ReviewEdit
                  statusData={this.state.data}
                  userInfo={this.props.authReducer.user}
                  tempateArrayD={this.props.template}
                  viewContainer={this.state.viewContainer}
                  approverData={(e) => this.approverData(e)}
                  supportData={(e) => this.supportData(e)}
                />
              )}
          </Container>
        )
    ];
  }
}

ApproverView.propTypes = {
  permissions: PropTypes.object,
  loadBreadCrums: PropTypes.func,
  loadApproval: PropTypes.func,
  approval: PropTypes.object,
  loadTaskUser: PropTypes.func,
  task: PropTypes.object,
  loadingApproval: PropTypes.bool,
  loadingTask: PropTypes.bool,
  loadTemplate: PropTypes.func,
  template: PropTypes.object,
  loadAuth: PropTypes.func,
  authReducer: PropTypes.func,
};

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    loadBreadCrums, loadApproval, loadTaskUser, loadTemplate, loadAuth
  }, dispatch)
);

const mapStateToProps = state => ({
  permissions: state.authReducer.permissions,
  approval: state.approvalReducer.approval,
  task: state.taskUserReducer.task,
  loadingApproval: state.approvalReducer.loadingApproval, 
  loadingTask: state.taskUserReducer.loadingTask,
  template: state.templateReducer.template,
  authReducer: state.authReducer,
});

export default connect(mapStateToProps, mapDispatchToProps)(ApproverView);
