export const LOAD_TEMPLATE = 'binary/template/LOAD_TEMPLATE';
export const LOAD_TEMPLATE_SUCCESS = 'binary/template/LOAD_TEMPLATE_SUCCESS';
export const LOAD_TEMPLATE_FAIL = 'binary/template/LOAD_TEMPLATE_FAIL';

export function loadTemplate(clientId, templatePayload) {
  return {
    types: [LOAD_TEMPLATE, LOAD_TEMPLATE_SUCCESS, LOAD_TEMPLATE_FAIL],
    payload: {
      client: 'ctlapi',
      request: {
        method: 'post',
        data: templatePayload,
        url: '/api/template/' + clientId + '/list'
      }
    }
  };
}
