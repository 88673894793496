import {
  LOAD_TEMPLATE,
  LOAD_TEMPLATE_SUCCESS,
  LOAD_TEMPLATE_FAIL
} from 'redux/actions/templateAction';

const initialState = {
  loadedTemplate: false,
  loadingTemplate: false,
  template: []
};

export default function templateReducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD_TEMPLATE:
      return {
        ...state,
        loadingTemplate: true,
        loadedTemplate: false
      };
    case LOAD_TEMPLATE_SUCCESS:
      const queueList = state.data ? { ...state.data, ...action.payload.data } : action.payload.data;
      return {
        ...state,
        loadingTemplate: false,
        loadedTemplate: true,
        template: queueList
      };
    case LOAD_TEMPLATE_FAIL:
      return {
        ...state,
        loadingTemplate: false,
        loadedTemplate: false,
        error: action.error
      };
    default:
      return state;
  }
}
